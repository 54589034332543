<template>
  <div class="register-box">
    <el-card shadow="never" class="card">
      <p class="header">忘记密码</p>
      <div class="formBox">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="username">
            <el-input
              placeholder="用户名"
              prefix-icon="el-icon-user"
              v-model.trim="form.username"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            />
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              placeholder="验证码"
              prefix-icon="el-icon-circle-check"
              v-model.trim="form.code"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            >
              <img
                :src="verifyUrl"
                alt="verifyCode"
                slot="suffix"
                class="verifyImg"
                @click="this.changeVerifyCode"
              />
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input placeholder="验证码" v-model.trim="form1.code">
              <span slot="suffix" class="getCode" @click="_getCode">
                {{ show ? '获取验证码' : `${count}s后重新获取` }}
              </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input
              placeholder="新密码"
              v-model.trim="form.passWord"
              type="password"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passWord2">
            <el-input
              placeholder="确认密码"
              v-model.trim="form.passWord2"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
        <p @click="_confirmRegister(form1.code)">确认</p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getForgetCode, getVerifyCode, getAuth } from './api'

const TIME_COUNT = 60
export default {
  data() {
    // 验证二次输入密码
    const validatePwd2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.passWord) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    // 验证是否是正确格式的手机号
    // const validatePhone = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('手机号不能为空'))
    //   } else {
    //     const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
    //     if (reg.test(value)) {
    //       callback()
    //     } else {
    //       return callback(new Error('请输入正确格式的手机号'))
    //     }
    //   }
    // }
    // 验证正确的密码格式: 6-16位，有数字和字母
    const passwordTest = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入6-18位并且必须包含数字和字母的密码'))
        }
      }
    }
    const rules = {
      // username: [{ validator: validatePhone, trigger: 'blur' }],
      username: [
        { required: true, message: '用户名不能为空', trigger: 'blur' },
      ],
      passWord: [{ validator: passwordTest, trigger: 'blur' }],
      passWord2: [
        {
          required: true,
          validator: validatePwd2,
          trigger: 'blur',
        },
      ],
      code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
    }
    return {
      show: true, // 开始的时候启用(可以发验证码)
      count: '',
      rules,
      timer: null,
      checked: false,
      form: {
        username: '',
        code: '',
        passWord: '',
        passWord2: '',
      },
      form1: {
        code: '',
      },
      verifyUrl: '',
    }
  },
  mounted() {
    this._getVerifyCode()
  },
  methods: {
    async _getVerifyCode() {
      try {
        this.verifyUrl = await getVerifyCode()
      } catch (err) {
        console.error(err)
      }
    },
    changeVerifyCode() {
      this._getVerifyCode()
    },
    handleHrefProtocol(url) {
      const res = this.$router.resolve(url)
      window.open(res.href, '_blank')
    },
    async _getCode() {
      if (!this.show || !this.form.username) {
        return
      }
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        try {
          const params = {
            username: this.form.username,
            code: this.form.code,
          }
          await getForgetCode(params)
          this.$message.success('发送成功，请注意查收')
        } catch (err) {
          console.error(err)
        }
      }
    },
    _confirmRegister(code) {
      // const code = this.form1.code
      // delete params.passWord2
      const params = { password: this.form.passWord }
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const result = await getAuth(code, params)
            if (!result) {
              this.$message.success('密码修改成功')
              this.$router.push('/login')
            }
          } catch (err) {
            console.error(err)
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.register-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 72px 16px 72px;
  .card {
    width: 100%;
    height: 100%;
    .header {
      position: relative;
      height: 60px;
      line-height: 60px;
      width: 100%;
      font-size: 24px;
      color: #333;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 32px;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 32px;
        background: #2c68ff;
        top: 14px;
        left: -20px;
      }
    }
    .formBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .verifyImg {
        display: inline-block;
        width: 104px;
        height: 45px;
        position: relative;
        top: 3px;
        cursor: pointer;
        //border: 1px solid #cccccc;
        border-radius: 8px;
      }
      form {
        width: 344px;
        .getCode {
          color: #2c68ff;
          padding-right: 6px;
          position: relative;
          top: 8px;
          cursor: pointer;
          font-size: 14px;
        }
      }
      & > p:nth-child(2) {
        width: 344px;
        height: 56px;
        background: #2c68ff;
        border-radius: 4px;
        text-align: center;
        line-height: 56px;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
        margin-bottom: 32px;
      }
      & > p:nth-child(3) {
        font-size: 16px;
        & > span:first-child {
          color: #ccc;
        }
        & > span:nth-child(2) {
          color: #2c68ff;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep.el-dialog__footer {
    text-align: center;
  }
  .dia-header {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    color: #2c68ff;
    border-left: 8px solid #2c68ff;
    padding-left: 16px;
  }
  .txt {
    line-height: 36px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .protocol {
    display: flex;
    flex-direction: column;
    span {
      cursor: pointer;
      line-height: 42px;
      color: #2c68ff;
      font-size: 14px;
    }
  }
}

::v-deep.el-input__inner {
  height: 56px;
}
::v-deep.el-dialog {
  box-sizing: border-box;
  padding: 0 48px 48px 48px;
}
</style>
